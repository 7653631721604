import React from 'react';
import {StyledHowBunnyWork} from './HowBunnyWork.styles';
import Button from '../Button';
import logo from '../../assets/logo-sm.svg';
import videoBg from '../../assets/videoBg.png';
import borderLine from '../../assets/border-line.png';
import playIcon from '../../assets/play-icon.svg';

const HowBunnyWork = () => {
    const works = [
        {
            title: 'Pick a Token You Want to Support:',
            desc: 'Browse through the tokens you are hodling and select one that you want to burn.',
        },
        {
            title: 'Burn Tokens on the Platform:',
            desc: 'Browse through the tokens you are hodling and select one that you want to burn.',
        },
        {
            title: 'Track the Burn and Leaderboards:',
            desc: 'Each burn is recorded and displayed on the platform, showing the amount burned, the wallet used, and a link to the transaction. This creates transparency and allows users to see the impact of their burns.',
        },
        {
            title: 'Compete on the Leaderboards:',
            desc: 'The platform features leaderboards showing which projects and wallets have burned the most tokens. These leaderboards can be filtered by different timeframes, such as 24 hours, 7 days, 30 days, and all-time. The top three projects and wallets are highlighted more prominently.',
        },
        {
            title: 'Daily Fee Distribution:',
            desc: 'When you burn tokens a 3% fee will be applied, this fee is collected across all token burns. Each day, the top 3 Projects will be selected, we will automatically purchase coins with the collected fees and Burn them. The Projects are displayed on the platform with links to their burn transactions.',
        },
    ];
    return (
        <StyledHowBunnyWork>
            <strong className="title">How $Burny Works</strong>
            <div className="desc">
                <p>
                    Burny is designed to promote the deflationary nature of tokens by systematically burning a portion
                    of the tokens, helping to increase their scarcity and potentially their value over time. Here’s how
                    the process works:
                </p>
            </div>
            <ul className="list-group">
                {works.map((elem, ind) => (
                    <li className="listItems">
                        <div className="image-wrapper">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="text">
                            <strong className="list-title">{elem.title}</strong>
                            <p>{elem.desc}</p>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="desc">
                <p>
                    Burny makes it easy for you to actively participate in token burning, helping to boost the value of
                    your chosen tokens. The platform’s transparent tracking and competitive leaderboards encourage
                    ongoing participation and community engagement.
                </p>
            </div>
            {/* <div className="video-wrap">
                <div className="fakeAfter">
                    <img src={borderLine} alt="borderLine" />
                </div>
                <div className="play-button">
                    <img src={playIcon} alt="playIcon" />
                </div>
                <img src={videoBg} alt="videoBg" />
                <div className="fakeBefore">
                    <img src={borderLine} alt="borderLine" />
                </div>
            </div> */}
            <div className="btnWrap">
                <Button variant={'primary'}>
                    Make burning great again
                    <img src={logo} alt="logo" />
                </Button>
            </div>
        </StyledHowBunnyWork>
    );
};

export default HowBunnyWork;
