import styled from 'styled-components';
export const StyledProjectsBurning = styled.div`
    padding: 20px 0;

    @media (min-width: 768px) {
        padding: 40px 0;
    }

    .head {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            display: block;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            @media (min-width: 576px) {
                font-size: 22px;
                line-height: 25px;
            }
        }

        .tabs-holder {
            position: relative;
            @media (max-width: 768px) {
                max-width: 110px;
                width: 100%;
            }
            .dropdown-button {
                display: none;
                border: 1px solid #489dfe;
                background: linear-gradient(89deg, #489dfe 22.59%, #3970fe 49.31%, #5f59ff 74.25%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border-radius: 6px;
                padding: 6px 12px;
                cursor: pointer;

                &.active {
                    .icon {
                        transform: scale(-1);
                    }
                }

                .icon {
                    color: #489dfe;
                    transform: scale(1);
                    transition: 0.3s;
                }

                @media (max-width: 767px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 3px;
                    width: 100%;
                    text-align: center;

                    font-weight: 600;
                }
            }

            .tabs {
                display: flex;
                gap: 8px;

                @media (max-width: 767px) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    flex-direction: column;
                    border-radius: 4px;
                    opacity: 0;
                    transform: translateY(20px);
                    transition: 0.3s;
                    border: 1px solid #489dfe;
                    background-color: #24214f;
                    margin-top: 8px;

                    &.open {
                        opacity: 1;
                        transform: translateY(0);
                        z-index: 10;
                    }

                    .tab {
                        padding: 10px;

                        &:last-child {
                            border-bottom: none;
                        }
                        &.active {
                            border: none;
                        }
                    }
                }
            }

            .tab {
                font-weight: 500;
                border-radius: 6px;
                padding: 4px 7px;
                transition: 0.3s;
                border: 1px solid transparent;
                cursor: pointer;
                color: var(--gray-50);

                &:hover,
                &.active {
                    border: 1px solid #489dfe;
                    background: linear-gradient(89deg, #489dfe 22.59%, #3970fe 49.31%, #5f59ff 74.25%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                @media (min-width: 576px) {
                    padding: 6px 12px;
                }
            }
        }
    }

    .cards-holder {
        &.desktop {
            display: none;
            @media (min-width: 576px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                gap: 16px;
            }
            @media (min-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        &.mobile {
            display: block;
            @media (min-width: 576px) {
                display: none;
            }
        }

        .slick-list{
            padding-bottom: 10px !important;
        }
        .slick-track {
            display: flex !important;
            gap: 10px;
            margin: 0 -3px;
        }
    }
`;
