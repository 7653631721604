import styled from "styled-components";
import bg from "../../assets/burnedBg.png";
export const StyledSuccessFullyBurned = styled.div`
  width: 100%;
  background: url(${bg});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 432px;
  text-align: center;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  .burned-title {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    text-transform: uppercase;
    background: linear-gradient(91deg, #fff 47.96%, #f43030 132.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 39px;
    }
    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 44px;
    }
    @media (min-width: 1024px) {
      font-size: 48px;
      line-height: 52px;
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 576px) {
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    }
  }
`;
