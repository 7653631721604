import React from 'react';
import {StyledCurrencyCard} from './CurrencyCard.styles';
import arrows from '../../assets/arrows-icon.svg';
import {IoIosArrowUp} from 'react-icons/io';

const CurrencyCard = ({
    danger,
    cardNumber,
    coinName,
    active,
    coinImg,
    totalCoins,
    dangerImg,
    successImg,
    percent,
    time,
}) => {
    return (
        <>
            <StyledCurrencyCard $danger={danger} $active={active}>
                <div className="card-head">
                    <span className="card-number">#{cardNumber}</span>
                    <div className="coin-holder">
                        <span className="currency-name">{coinName}</span>
                        <figure className="coin-img">
                            <img src={coinImg} alt="usdc" />
                        </figure>
                    </div>
                </div>
                <div className="total-burned">
                    <span>Total Burned</span>
                    <img src={arrows} alt="arrows" />
                </div>
                <div className="total-coins">
                    <span>{totalCoins}</span>
                </div>
                <span className="burn-rate">Burn Rate</span>
                <div className="graph-holder">
                    <figure className="graph-img">
                        {danger ? (
                            <img src={dangerImg} alt="dangerImg" />
                        ) : (
                            <img src={successImg} alt="successGraphImg" />
                        )}
                    </figure>
                    <div>
                        <div className="percentage">
                            <span>{percent}%</span>
                            <IoIosArrowUp className="arrow-icon" />
                        </div>
                        <span className="time">{time}</span>
                    </div>
                </div>
            </StyledCurrencyCard>
        </>
    );
};

export default CurrencyCard;
