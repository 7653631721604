import React, {useState} from 'react';
import {StyledTwoCol} from './TwoCol.styles';
import Button from '../Button';
import logo from '../../assets/logo-sm.svg';
import CenterModal from '../Modal/CenterModal';
import HowBunnyWork from '../HowBunnyWork';
const TwoCol = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="container">
            <StyledTwoCol>
                <div className="col">
                    <div className="textwrap">
                        <strong className="title">Copy $BURNY Token Address</strong>
                        <span>3aG9P ... oJxKp</span>
                    </div>
                    <div className="buttonwrap">
                        <Button onClick={() => setShowModal(true)}>
                            COPY ADDRESS
                            <img src={logo} alt="logo" />
                        </Button>
                    </div>
                </div>
                <div className="col">
                    <div className="textwrap">
                        <strong className="title">telegram Notification bot</strong>
                        <div className="view-transaction">
                            <span className="time">now</span>

                            <div className="icon-wrap">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="15"
                                    viewBox="0 0 19 15"
                                    fill="none">
                                    <path
                                        d="M1.65876 6.51353C1.65876 6.51353 9.65876 3.23033 12.4333 2.07423C13.4969 1.61183 17.1038 0.132026 17.1038 0.132026C17.1038 0.132026 18.7686 -0.515334 18.6298 1.05689C18.5836 1.70431 18.2136 3.97017 17.8437 6.42105C17.2888 9.88927 16.6876 13.6811 16.6876 13.6811C16.6876 13.6811 16.5951 14.7448 15.809 14.9297C15.0229 15.1147 13.7281 14.2824 13.4969 14.0973C13.3119 13.9586 10.0287 11.8777 8.82638 10.8603C8.50267 10.5829 8.13275 10.028 8.87259 9.3806C10.5374 7.85459 12.5258 5.95865 13.7281 4.75635C14.283 4.2014 14.8379 2.90662 12.5258 4.47884C9.24257 6.74476 6.00558 8.87193 6.00558 8.87193C6.00558 8.87193 5.26568 9.33433 3.87841 8.91814C2.49108 8.50201 0.872588 7.94706 0.872588 7.94706C0.872588 7.94706 -0.237172 7.25343 1.65876 6.51353Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <div className="text-wrap">
                                <span>🔥 Token Burned Alert 🔥</span>
                                <div className="view-transaction-wrap">
                                    <label>View Transaction</label>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7"
                                        height="13"
                                        viewBox="0 0 7 13"
                                        fill="none">
                                        <path
                                            d="M1 11.4197L5.63174 6.78794C5.827 6.59268 5.827 6.2761 5.63174 6.08083L1.13124 1.58033"
                                            stroke="url(#paint0_linear_49_28305)"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_49_28305"
                                                x1="0.985351"
                                                y1="6.49963"
                                                x2="5.98535"
                                                y2="6.49963"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#499DFE" />
                                                <stop offset="1" stop-color="#605AFF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttonwrap">
                        <Button>
                            go to Telegram
                            <img src={logo} alt="logo" />
                        </Button>
                    </div>
                </div>
            </StyledTwoCol>
            <CenterModal open={showModal} width="1092" bg setOpen={setShowModal}>
                <HowBunnyWork />
            </CenterModal>
        </div>
    );
};

export default TwoCol;
