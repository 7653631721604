import styled from 'styled-components';

export const StyledTableLayout = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 32px;
    .childWrapper {
        border: 1px solid rgba(73, 158, 255, 0.5);
        border-radius: 8px;
    }
    .title-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 5px;

        .filterSelect {
            max-width: 120px;
            width: 100%;
        }
    }
    .table-title {
        color: var(--white);
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        margin-bottom: 16px;
        @media (min-width: 768px) {
            font-size: 22px;
            line-height: 26px;
        }
    }
    .title {
        display: block;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: var(--white);
        margin: 0 0 5px;
    }

    .description {
        width: 100%;
        margin: 0 0 5px;
        font-size: 14px;
        color: var(--gray-50);

        img {
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: auto;
        }

        span {
            color: var(--white);
        }
    }
`;
