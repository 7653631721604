import styled from 'styled-components';

export const StyledYesterDayProject = styled.div`
    position: relative;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #499eff;
    margin-bottom: 22px;
    background: linear-gradient(164deg, rgba(0, 0, 0, 0) 32%, #4963ff 107.26%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%),
        linear-gradient(258deg, rgba(31, 31, 82, 0.7) 30.87%, rgba(27, 21, 77, 0.7) 75.41%);
    box-shadow: 0px 4px 40px 0px rgba(69, 147, 255, 0.5);
    backdrop-filter: blur(5px);
    /* 11.19% */
    @media (min-width: 576px) {
        padding: 20px;
        margin-bottom: 32px;
    }
    @media (min-width: 1200px) {
        padding: 40px;
    }

    .heading {
        display: block;
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 25px;
        text-transform: uppercase;

        @media (max-width: 768px) {
            background: linear-gradient(89deg, #fff 46.93%, #5032d3 101.49%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .card-holder {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        @media (min-width: 620px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: 1200px) {
            gap: 20px;
        }
    }

    .fakeBg {
        z-index: -1;
        position: absolute;
        bottom: 0;
        user-select: none;
        left: 22%;

        @media (max-width: 992px) {
            left: 10%;
        }
        @media (max-width: 768px) {
            left: 0;
        }
    }
`;
