import React from 'react';
import usdt from '../../assets/usdt.svg';
import robotImg from '../../assets/robot-img.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {StyledBurnTransaction} from './BurnTransaction.styles';
import BurnTransactionCard from '../BurnTransactionCard/BurnTransactionCard';
const walletData = [
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
    {
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        totalCoin: '261,665 USD',
    },
];

const BurnTransaction = () => {
    var settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.2,
                },
            },
        ],
    };
    return (
        <StyledBurnTransaction>
            <div className="container">
                <strong className="heading">Previous burn transactions</strong>
                <div className="cards-holder desktop">
                    {walletData?.map((item, index) => (
                        <BurnTransactionCard
                            key={index}
                            date={item?.date}
                            coinImg={item?.coinImg}
                            coinName={item?.coinName}
                            user={item?.user}
                            totalCoin={item?.totalCoin}
                        />
                    ))}
                </div>
                <div className="cards-holder mobile">
                    <Slider {...settings}>
                        {walletData?.map((item, index) => (
                            <BurnTransactionCard
                                key={index}
                                date={item?.date}
                                coinImg={item?.coinImg}
                                coinName={item?.coinName}
                                user={item?.user}
                                totalCoin={item?.totalCoin}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </StyledBurnTransaction>
    );
};

export default BurnTransaction;
