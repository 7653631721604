import React from 'react';
import {StyledHeader, UserStatus} from './Header.styles';
import logo from '../../assets/logo.svg';
import logout from '../../assets/logout.svg';
import {Link, useLocation} from 'react-router-dom';
// import dots from '../../assets/dots.svg';
import {useAuth} from '../../Context/AuthContext';

const Header = () => {
    const location = useLocation();
    const {isAuthorized, unAuthorize} = useAuth();
    return (
        <StyledHeader>
            <div className="container">
                <Link to="/">
                    <div className="logoWrapper">
                        <img src={logo} alt="logo" />
                        <span>BY $FKETH AND $GME</span>
                    </div>
                </Link>
                {location.pathname !== '/' && (
                    <UserStatus>
                        <div className="status">
                            <div className={`circle ${isAuthorized ? 'bg-success' : 'bg-danger'}`} />
                            <span> {isAuthorized ? '3aG9P ... oJxKp' : 'Not Authorized'} </span>
                        </div>
                        {isAuthorized && (
                            <>
                                <div className="status" onClick={unAuthorize}>
                                    <img src={logout} alt="logout" />
                                </div>
                                {/* Do not remove this */}

                                {/* <div className="status">
                                <img src={dots} alt="dots" />
                            </div> */}
                            </>
                        )}
                    </UserStatus>
                )}
            </div>
        </StyledHeader>
    );
};

export default Header;
