import styled from 'styled-components';

export const StyledCheckbox = styled.div`
    display: block;
    margin-bottom: 15px;

    .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .form-group label {
        font-size: 17px;
        line-height: 21px;
        position: relative;
        cursor: pointer;
        color: var(--gray-50);
        font-weight: 500;
    }

    .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #fff;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
    }

    .form-group input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 8px;
        width: 5px;
        height: 11px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;
