/* eslint-disable import/first */
const {createGlobalStyle} = require('styled-components');
import bg from './assets/main-bg.png';

const GlobalStyles = createGlobalStyle`
  :root {
 
    --primary: #483c5b;
    --primary-25: #cabbbe;
    --secondary-100: #895dcf;
    --gray-50: #96949E;
    --gray-100: #222128;
    --gray-150: #3D3D43;
    --gray-200: #959595;
    --danger: #D24724;
    --body-color: white;
    --white: #fff;
    --white-50: #fafafa;
    --white-100: #f9f9f9;
    --info : #4683DF;
    --info-50 : #efe2c8;
    --success: #33E970;
    --success-50: #92aeaf;
    --success-100: #2f4d4a;
    --warning: #EDBE44;
    --black: #252429;
    --body-bg: #111014;
    --black-50: #181C17;
    --body-text: white;
    --body-text-25: #7c7b7c;

    /* Body */
    --base-text-color: var(--body-text);
    --base-background-color: var(--body-color);
    --font-size-base: 16px;
    --line-height-base: 20px;
    --base-font-family: var(--base-font-sans-serif);
    --base-min-width: 375px;
  
  }
 
  * {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
  }
  *:before,
  *:after,
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color:  #76B6FF1A;
;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #489DFE;
    border-radius: 5px;
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--base-text-color);
    font-family: "Blender Pro", sans-serif;
    font-size:var(--font-size-base);
    line-height:var(--line-height-base) ;
    font-weight: 300;
    position: relative;
    min-width: var(--base-min-width);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 0px !important;
    background: var(--body-bg);
  }
  .container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    @media (min-width: 768px){
    padding: 0 40px;
    }
  }
 .Toastify__toast-container {
    max-width: 369px;
    width: 100%;
  }
  .Toastify__close-button--light{
    color: #323136;
    opacity: 1;
    top: 20px !important;
  }
  .content-section {
    gap: 13px;
    flex-grow: 1;

    @media (min-width: 1200px) {
      display: flex;
    }
  }
  .burn-detail-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    .col{
      display: flex;
      gap: 4px;
         @media (min-width: 576px) {
      gap: 8px;
    
    }
    }
    .btn-p{
      padding: 8px;

      border-radius: 8px;
      border: 1px solid rgba(73, 158, 255, 0.5);
      backdrop-filter: blur(5px);
      color: #FFF;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    @media (min-width: 576px) {
      padding: 15px 24px;

      font-size: 17px;
      line-height: 21px;}
    }
    .btn-action-detail{
      cursor: pointer;
      padding: 5px;
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
border: 1px solid rgba(73, 158, 255, 0.5);
backdrop-filter: blur(5px);
 @media (min-width: 576px) {
   width: 52px;
      height: 52px;
    }

    }
  }
.action-Btn-table{
  display: inline-flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  label{
  cursor: pointer;
    display: block;
    background:linear-gradient(77.32deg, #489DFE 14.1%, #3044F4 113.26%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }
}
.textgradient{
   display: block;
   background: linear-gradient(91deg, #FFF 47.96%, #3044F4 132.02%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.fakeLabelWrapp {
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    opacity: 0.6;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
    margin-left: 32px;
    position: relative;
  }

  
  
  .container-fluid{
    max-width: 100%;
    padding: 0 15px;
  }

  .item-box {
    display: flex;
    align-items: center;
    .fav{
      padding-right: 8px;
    }
    

    .img-box {
      width: 30px;
      position: relative;
      .isfav{
        position: absolute;
      right: -3px;
    bottom: -3px;

    }
    }



    .text-box {
      flex-grow: 1;
      padding: 0 0 0 10px;
    }

    .title {
      display: block;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      margin: 0 0 2px;
    }
  }
  br{
      @media (max-width: 575px){
        display: none;
      }
    }
  .cell-title {
    display: block;
    font-weight: 500;
    margin: 0 0 3px;
  }

  .icon-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    gap: 4px;

    li {
      margin: 0;
      flex-shrink: 0;
    }
  }

  .copy-link {
    display: inline-flex;
    gap: 5px;
    color: var(--gray-50);
  }

  .title-img {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 0 3px;
    color: var(--white);
  }

  .white {
    color: var(--white);
  }


.menu-opener{
  position: absolute;
  background: red;
  z-index: 10;
  left: 20px;
  top: 25px;
  padding: 10px ;
  border-radius: 10px;
  color: var(--white);
  background: var(--success);
  font-size: 20px;
  line-height: 1px;
  @media screen and (min-width: 576px){
    display: none;
  }
}
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 98;
  }

  .wrapper {
    background: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    padding: 0px 0 40px;

    
  }
 
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  figure{
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  textarea {
    resize: vertical;
    vertical-align: top;
  }

  button,
  input[type="button"],
  input[type="reset"],
  input[type="file"],
  input[type="submit"] {
    cursor: pointer;
    font-family: var( --base-font-sans-serif);
  }

  form,
  fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
  }
  a {
    text-decoration: none;
    color: var(--black);
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  button {
    transition: opacity var(--animation-speed) ease-in-out,
      background var(--animation-speed) ease-in-out,
      visibility var(--animation-speed) ease-in-out,
      border var(--animation-speed) ease-in-out,
      color var(--animation-speed) ease-in-out;
  }

  button {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    font-family: var(--font-size-base);
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 0 0 15px;
    color: var(--body-text);
    font-weight: 400;
  }
  h1,
  .h1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
   
    @media screen and (min-width: 768px){
    font-size: 40px;
    line-height: 44px;
    }
    @media screen and (min-width: 992px){
    font-size: 50px;
    line-height: 54px;
    }
    @media screen and (min-width: 1200px){
       font-size: 75px; 
    line-height: 79px;
    }
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    @media screen and (min-width: 768px){
    font-size: 35px;
    line-height: 40px;
    }
    @media screen and (min-width: 1200px){
        font-size: 45px;
        line-height: 49px;
    }

  }
  h3,
  .h3 {
    font-size: 26px;
    line-height: 30px;
  }
  h4,
  .h4 {
    font-size: 14px;
    line-height: 23px;
    @media (min-width: 576px){
      font-size: 16px;
    }
    @media (min-width: 786px){
      font-size: 18px;
    }
  }

  p {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }
  

  /* Track */
 
  /* Handle on hover */
  /* Remove Arrows/Spinners from input type number */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
  }
 
`;
export default GlobalStyles;
