import React from 'react';
import {DetailSection, OverViewAccount, StyledBurnDetail} from './BurnDetail.styles';
import profile from '../../assets/profile.png';
import coin from '../../assets/ustd.svg';
import copy from '../../assets/copy-icon.svg';
import logo from '../../assets/logo-sm.svg';
import Send from '../../assets/Send.svg';
import checkBox from '../../assets/success-check.svg';
import line from '../../assets/border-line.png';
import downloadIcon from '../../assets/download-icon.svg';
import Button from '../Button';
const BurnDetail = () => {
    const handleCopy = text => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };
    return (
        <div className="container">
            <div className="burn-detail-header">
                <div className="col">
                    <div className="btn-p">676573gdfgsgfuvsgyt784y7y7y45hffvh47</div>
                    <div className="btn-action-detail">
                        <img src={copy} alt="copy" />
                    </div>
                </div>
                <div className="col">
                    <div className="btn-action-detail">
                        <img src={downloadIcon} alt="downloadIcon" />
                    </div>
                </div>
            </div>
            <StyledBurnDetail>
                <img src={line} alt="line" className="fake-border" />
                <h1>burn details</h1>
                <DetailSection>
                    <div className="profile">
                        <img src={profile} alt="profile" />
                    </div>
                    <span className="walletAddress">3aG9P ... oJxKp</span>
                    <span className="date">22 : 08 : 2023</span>
                    <div className="amount-holder">
                        <div className="account-details">
                            <span className="fake-label">From</span>
                            <div className="address">
                                <img src={coin} alt="coin" />
                                <span className="address-wrap">3aG9P ... oJxKp</span>
                            </div>
                        </div>
                        <div className="amount-wrap">
                            <strong className="amount">261,665 USD</strong>
                            <span>2.400 USDC</span>
                        </div>
                        <div className="account-details">
                            <span className="fake-label">To</span>
                            <div className="address">
                                <span className="address-wrap">3aG9P ... oJxKp</span>
                            </div>
                        </div>
                    </div>
                </DetailSection>
                <OverViewAccount>
                    <label className="title">Overview</label>
                    <ul>
                        <li>
                            <div className="col-1">
                                <span>Signature</span>
                            </div>
                            <div className="col-2 flex">
                                <span className="longtext">
                                    434RD4ytJvErxQt1zoKitSnzf9z4jgmtSzMsuWDtxDa1EggiFt7x3EcbdxmXdLQ48L1LotSGJ3nKwjeqsUvzUhpK
                                </span>
                                <img
                                    src={copy}
                                    alt="copy"
                                    className="copy-icon"
                                    onClick={() =>
                                        handleCopy(
                                            '434RD4ytJvErxQt1zoKitSnzf9z4jgmtSzMsuWDtxDa1EggiFt7x3EcbdxmXdLQ48L1LotSGJ3nKwjeqsUvzUhpK'
                                        )
                                    }
                                />
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Block</span>
                            </div>
                            <div className="col-2 flex">
                                <span className="longtext">286335363</span>
                                <img
                                    src={copy}
                                    alt="copy"
                                    className="copy-icon"
                                    onClick={() => handleCopy('286335363')}
                                />
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Signature</span>
                            </div>
                            <div className="col-2 success flex">
                                <img src={checkBox} alt="checkBox" />
                                <span className="border-right">Success</span>
                                <span>Finalized (MAX Confirmations)</span>
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Signer</span>
                            </div>
                            <div className="col-2 flex">
                                <span className="longtext">85N8GgTtSTfeK7KAkwwZMSDKrhkT51S8tjswgrgTLbjo</span>
                                <img
                                    src={copy}
                                    alt="copy"
                                    className="copy-icon"
                                    onClick={() => handleCopy('85N8GgTtSTfeK7KAkwwZMSDKrhkT51S8tjswgrgTLbjo')}
                                />
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Fee</span>
                            </div>
                            <div className="col-2">
                                <span>0.00001 SOL ($0.001415)</span>
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Priority Fee</span>
                            </div>
                            <div className="col-2">
                                <span>0.000005 SOL ($0.000707)</span>
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Compute Units Consumed</span>
                            </div>
                            <div className="col-2">
                                <span>45,416</span>
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Transaction Version</span>
                            </div>
                            <div className="col-2">
                                <span>0</span>
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Address Lookup Table Account(s)</span>
                            </div>
                            <div className="col-2 flex">
                                <span className="longtext">2immgwYNHBbyVQKVGCEkgWpi53bLwWNRMB5G2nbgYV17</span>
                                <img
                                    src={copy}
                                    alt="copy"
                                    className="copy-icon"
                                    onClick={() => handleCopy('2immgwYNHBbyVQKVGCEkgWpi53bLwWNRMB5G2nbgYV17')}
                                />
                            </div>
                        </li>
                        <li>
                            <div className="col-1">
                                <span>Previous Block Hash</span>
                            </div>
                            <div className="col-2">
                                <span className="longtext">HFQwcYWKBFF3T9XAAyYagaHzKhE8Xn1Nfwm6MAoZmSKZ</span>
                            </div>
                        </li>
                    </ul>
                </OverViewAccount>
                <div className="btn-wrap">
                    <Button outline={'primary'}>
                        share link
                        <img src={Send} alt="Send" />
                    </Button>
                    <Button variant={'primary'}>
                        view solscan
                        <img src={logo} alt="logo" />
                    </Button>
                </div>
            </StyledBurnDetail>
        </div>
    );
};

export default BurnDetail;
