import React from 'react';
import {StyledWalletBurning} from './WalletBurning.styles';
import WalletCard from '../WalletCard';
import usdt from '../../assets/usdt.svg';
import robotImg from '../../assets/robot-img.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const walletData = [
    {
        cardNumber: 1,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 2,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 3,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 4,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 5,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 6,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 7,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
    {
        cardNumber: 8,
        date: '22 : 08 : 2023',
        coinImg: usdt,
        coinName: 'USDT',
        user: '3aG9P ... oJxKp',
        userImg: robotImg,
        totalCoin: '261,665 USD',
    },
];

const WalletBurning = () => {
    var settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.2,
                },
            },
        ],
    };
    return (
        <StyledWalletBurning>
            <div className="container">
                <strong className="heading">Biggest Wallets Burning</strong>
                <div className="cards-holder desktop">
                    {walletData?.map((item, index) => (
                        <WalletCard
                            key={index}
                            cardNumber={item?.cardNumber}
                            date={item?.date}
                            coinImg={item?.coinImg}
                            coinName={item?.coinName}
                            user={item?.user}
                            userImg={item?.userImg}
                            totalCoin={item?.totalCoin}
                        />
                    ))}
                </div>
                <div className="cards-holder mobile">
                    <Slider {...settings}>
                        {walletData?.map((item, index) => (
                            <WalletCard
                                key={index}
                                cardNumber={item?.cardNumber}
                                date={item?.date}
                                coinImg={item?.coinImg}
                                coinName={item?.coinName}
                                user={item?.user}
                                userImg={item?.userImg}
                                totalCoin={item?.totalCoin}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </StyledWalletBurning>
    );
};

export default WalletBurning;
