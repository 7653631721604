import styled, {css} from 'styled-components';

export const TableHolder = styled.div`
    padding: ${({noPadding}) => (noPadding ? '0' : '20px 20px 10px')};
    background: #131335;
    /* padding-top: 10px; */
    border-radius: 8px;
    overflow: hidden;
    /* min-height: calc(100% - 60px); */

    @media (max-width: 991px) {
        padding: 10px;
        ${({$hasButton}) =>
            $hasButton &&
            css`
                position: relative;
                padding-bottom: 80px !important;
            `}
    }

    ${({responsive}) =>
        responsive
            ? css`
                  @media (min-width: 768px) {
                      padding: 1.25rem;
                  }
                  @media (min-width: 992px) {
                      padding: 0;
                  }
              `
            : css`
                  /* background: var(--white); */
              `}
    ${({$hasButton}) =>
        $hasButton &&
        css`
            position: relative;
            padding-bottom: 80px !important;
        `}
`;

export const TableScroll = styled.div`
    width: 100%;
    overflow-x: auto;
    max-height: ${({$height}) => $height && `${$height}px`};
`;

export const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    color: var(--gray-50);

    ${({responsive}) =>
        responsive
            ? css`
                  @media (min-width: 992px) {
                      min-width: ${({$width}) =>
                          $width && `${$width}px`}; /* width on which horizontal scroll will appear */
                  }
              `
            : css`
                  min-width: ${({$width}) => $width && `${$width}px`};
              `}
`;

export const Thead = styled.thead`
    border-radius: 8px 8px 0px 0px;
    background: rgba(255, 255, 255, 0.02);
    ${({responsive}) =>
        responsive
            ? css`
                  @media (max-width: 991px) {
                      display: none;
                  }
              `
            : css`
                  display: table-header-group;
              `}
`;

export const TBody = styled.tbody`
    ${({responsive}) =>
        responsive &&
        css`
            @media (max-width: 991px) {
                display: grid;
                grid-template-columns: repeat(2, minmax(0px, 1fr));
                gap: 10px;
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, minmax(0px, 1fr));
            }
        `}
`;

export const NoRecordFound = styled.span`
    display: block;
    max-width: 200px;
    padding: 15px 10px 13px;
    margin: 15px auto;
    border-radius: 5px;
    color: var(--danger);
    background: #ffebeb;
    text-align: center;
`;
