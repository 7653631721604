import React from 'react';
import {StyledFooter} from './Footer.styles';
import {Link} from 'react-router-dom';
import telegram from '../../assets/telegram.svg';
import tweet from '../../assets/tweet.svg';
import eagle from '../../assets/eagle.svg';
const Footer = () => {
    return (
        <StyledFooter>
            <div className="link-wrapper">
                <Link to="/">
                    <img src={tweet} alt="tweet" />
                </Link>
            </div>
            <div className="link-wrapper">
                <Link to="/">
                    <img src={eagle} alt="eagle" />
                </Link>
            </div>
            <div className="link-wrapper">
                <Link to="/">
                    <img src={telegram} alt="telegram" />
                </Link>
            </div>
        </StyledFooter>
    );
};

export default Footer;
