import doge from '../assets/doge.svg';
import solana from '../assets/solana.svg';
import ustd from '../assets/ustd.svg';
import ada from '../assets/ada.svg';
import fkth from '../assets/fkth.svg';

export const selectValues = [
    {
        label: (
            <>
                <img src={solana} alt="solana" p />
                Solana
            </>
        ),
        value: 'solana',
    },
    {
        label: (
            <>
                <img src={ustd} alt="ustd" />
                USDT
            </>
        ),
        value: 'ustd',
    },
    {
        label: (
            <>
                <img src={ada} alt="ada" />
                ADA
            </>
        ),
        value: 'ada',
    },
    {
        label: (
            <>
                <img src={fkth} alt="fkth" />
                fkth
            </>
        ),
        value: 'fkth',
    },
    {
        label: (
            <>
                <img src={doge} alt="doge" />
                doge
            </>
        ),
        value: 'doge',
    },
];
