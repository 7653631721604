import React, {useState} from 'react';
import ada from '../../assets/ada.svg';
import egld from '../../assets/egld.svg';
import ron from '../../assets/ron.svg';
import rose from '../../assets/rose.svg';
import solama from '../../assets/solama.svg';
import popcat from '../../assets/popcat.svg';
import mew from '../../assets/mew.svg';
import dogwifhat from '../../assets/dogwifhat.svg';
import {IoIosArrowDown} from 'react-icons/io';
import successGraphImg from '../../assets/success-graph-line.png';
import dangerGraphImg from '../../assets/danger-graph-line.png';
import {StyledProjectsBurning} from './ProjectsBurning.styles';
import CurrencyCard from '../CurrencyCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const currencyData = [
    {
        cardNumber: 1,
        coinName: 'Solama',
        coinImg: solama,
        totalCoins: '589,271,921,983,403 USDC',
        successImg: successGraphImg,
        percent: '23.50',
        time: 'Last 24hrs',
    },
    {
        cardNumber: 2,
        coinName: 'WIF',
        coinImg: dogwifhat,
        totalCoins: '589,271,921,983,403 SOL',
        dangerImg: dangerGraphImg,
        percent: '12,50',
        time: 'Last 24hrs',
        danger: true,
    },
    {
        cardNumber: 3,
        coinName: 'Popcat',
        coinImg: popcat,
        totalCoins: '589,271,921,983,403 USDT',
        successImg: successGraphImg,
        percent: '23.50',
        time: 'Last 24hrs',
    },
    {
        cardNumber: 4,
        coinName: 'MEW',
        coinImg: mew,
        totalCoins: '589,271,921,983,403 DOGE',
        dangerImg: dangerGraphImg,
        percent: '12.50',
        time: 'Last 24hrs',
        danger: true,
    },
    {
        cardNumber: 5,
        coinName: 'ADA',
        coinImg: ada,
        totalCoins: '589,271,921,983,403 ADA',
        successImg: successGraphImg,
        percent: '23.50',
        time: 'Last 24hrs',
    },
    {
        cardNumber: 6,
        coinName: 'EGLD',
        coinImg: egld,
        totalCoins: '589,271,921,983,403 EGLD',
        dangerImg: dangerGraphImg,
        percent: '12.50',
        time: 'Last 24hrs',
        danger: true,
    },
    {
        cardNumber: 7,
        coinName: 'RON',
        coinImg: ron,
        totalCoins: '589,271,921,983,403 RON',
        dangerImg: dangerGraphImg,
        percent: '12.50',
        time: 'Last 24hrs',
        danger: true,
    },
    {
        cardNumber: 8,
        coinName: 'ROSE',
        coinImg: rose,
        totalCoins: '589,271,921,983,403 ROSE',
        successImg: successGraphImg,
        percent: '23.50',
        time: 'Last 24hrs',
    },
];

const tabsData = [
    {
        tabName: 'Today',
    },
    {
        tabName: '24 hours',
    },
    {
        tabName: '7 days',
    },
    {
        tabName: '30 days',
    },
    {
        tabName: 'All Time',
    },
];

const ProjectsBurning = () => {
    const [tabActive, setTabActive] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    var settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.2,
                },
            },
        ],
    };

    return (
        <StyledProjectsBurning>
            <div className="container">
                <div className="head">
                    <span className="heading">Biggest Projects Burning</span>
                    <div className="tabs-holder">
                        <button
                            className={isDropdownOpen ? 'dropdown-button active' : 'dropdown-button'}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span> {tabsData[tabActive]?.tabName}</span> <IoIosArrowDown className="icon" />
                        </button>
                        <div className={`tabs ${isDropdownOpen ? 'open' : ''}`}>
                            {tabsData?.map((item, index) => (
                                <div
                                    key={index}
                                    className={tabActive === index ? 'tab active' : 'tab'}
                                    onClick={() => {
                                        setTabActive(index);
                                        setIsDropdownOpen(false);
                                    }}>
                                    <span>{item?.tabName}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="cards-holder desktop">
                    {currencyData?.map((item, index) => (
                        <CurrencyCard
                            active={index < 3}
                            key={index}
                            danger={item?.danger}
                            cardNumber={item.cardNumber}
                            coinName={item?.coinName}
                            coinImg={item?.coinImg}
                            totalCoins={item?.totalCoins}
                            successImg={item?.successImg}
                            dangerImg={item?.dangerImg}
                            percent={item?.percent}
                            time={item?.time}
                        />
                    ))}
                </div>
                <div className="cards-holder mobile">
                    <Slider {...settings}>
                        {currencyData?.map((item, index) => (
                            <CurrencyCard
                                key={index}
                                active={index < 3}
                                danger={item?.danger}
                                cardNumber={item.cardNumber}
                                coinName={item?.coinName}
                                coinImg={item?.coinImg}
                                totalCoins={item?.totalCoins}
                                successImg={item?.successImg}
                                dangerImg={item?.dangerImg}
                                percent={item?.percent}
                                time={item?.time}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </StyledProjectsBurning>
    );
};

export default ProjectsBurning;
