import React, { useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { PaginationHolder } from "./Pagination.styles";
import { MdArrowForward, MdOutlineArrowBack } from "react-icons/md";

const Pagination = ({
  page,
  totalCount,
  totalPages = 0,
  pageSize,
  onPageChange = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState(page);

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };
  //For previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const handleCurrentPage = (e) => {
    onPageChange(e);
  };
  return (
    <PaginationHolder className="pagination">
      {currentPage} of {totalPages}
      <div className="pages-holder">
        <button onClick={handlePrevPage}>
          <MdOutlineArrowBack size={25} />
        </button>
        <div className="pages">{currentPage}</div>

        <button onClick={handleNextPage}>
          <MdArrowForward size={25} />
        </button>
      </div>
    </PaginationHolder>
  );
};

export default Pagination;
