import styled, { css } from "styled-components";

export const Closer = styled.div``;

export const StyledModal = styled.div`
  position: fixed;
  inset: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: 0.3s all ease-in-out;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ $zIndex }) => ($zIndex ? $zIndex : "99")};
  .title {
    color: var(--dark);
  }
`;

export const ContentHolder = styled.div`
  position: relative;
  max-width: ${({ width }) => (width ? `${width}px` : "100%")};
  width: ${({ width }) => (width ? "100%" : "")};
  padding: ${({ padding }) => padding ?? ""}; // must prop
  background: ${({ bg }) => bg ?? ""}; // must props
  border-radius: 30px;
  max-width: ${({ width }) => (width ? `${width}px` : "100%")};
  width: ${({ width }) => (width ? "100%" : "")};
  padding: ${({ padding }) => padding ?? ""}; // must prop
  background: ${({ bg }) => bg ?? ""}; // must props
  border-radius: ${({ radius }) => radius ?? "30px"};
  animation: myAnim 0.3s ease;
  /* background: var(--white); */
  max-height: 100%;
  overflow-y: ${({ overflow }) => (overflow ? "" : "auto")};
  ${({ $bg }) =>
    $bg &&
    css`
      border: 1px solid rgba(73, 158, 255, 0.5);
      background: linear-gradient(
        258deg,
        rgba(31, 31, 82, 0.4) 30.87%,
        rgba(27, 21, 77, 0.4) 75.41%
      );
    `}
  @keyframes myAnim {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const Head = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 15px;
  top: 15px;
  .closer {
    z-index: 2;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #d9d9d9;
    font-size: 25px;
    &:hover {
      img {
        filter: invert(53%) sepia(100%) saturate(315%) hue-rotate(106deg)
          brightness(91%) contrast(92%);
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .notaccesspic {
      width: 35px;
      height: 35px;
    }
  }
`;
