import styled from 'styled-components';
export const StyledCommingSoon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        width: 100%;
    }
    .commingsoon {
        padding: 0 15px;
        overflow: hidden;
        position: relative;
        width: 100%;
        min-height: calc(100vh - 132px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid rgba(73, 158, 255, 0.5);
        background: linear-gradient(258deg, rgba(31, 31, 82, 0.4) 30.87%, rgba(27, 21, 77, 0.4) 75.41%);

        .fakeBg {
            z-index: 1;
            position: absolute;
            bottom: 0;
            user-select: none;
        }
        .content {
            position: relative;
            width: 100%;
            margin: 0 auto;
            z-index: 5;
            text-align: center;
            h1 {
                text-align: center;
                font-size: 40px;
                line-height: 44px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 32px;
                background: linear-gradient(91deg, #fff 47.96%, #3044f4 132.02%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @media (min-width: 768px) {
                    font-size: 60px;
                    line-height: 64px;
                }
                @media (min-width: 1024px) {
                    font-size: 80px;
                    line-height: 84px;
                }
            }
        }
    }
    .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        @media (max-width: 576px) {
            gap: 5px;
            max-width: 300px;
            margin: 0 auto;
            flex-direction: column-reverse;
            button {
                width: 100%;
            }
        }
    }
`;
