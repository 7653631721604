import React from 'react';
import {IoIosArrowForward} from 'react-icons/io';
import {StyledWalletCard} from './WalletCard.styles';

const WalletCard = ({cardNumber, date, coinImg, coinName, user, userImg, totalCoin}) => {
    return (
        <StyledWalletCard>
            <div className="card-head">
                <span className="card-number">#{cardNumber}</span>
                <div className="date-holder">
                    <span>{date}</span>
                </div>
            </div>
            <div className="user-holder">
                <div className="coin-holder">
                    <figure className="coin-img">
                        <img src={coinImg} alt="coinImg" />
                    </figure>
                    <span className="text">{coinName}</span>
                </div>
                <div className="user">
                    <span className="text">{user}</span>
                    <figure className="img-holder">
                        <img src={userImg} alt="usdc" />
                    </figure>
                </div>
            </div>
            <div className="card-footer">
                <div className="total-coins">
                    <span>{totalCoin}</span>
                </div>
                <div className="screener">
                    <span className="text">Dexscreener</span>
                    <IoIosArrowForward />
                </div>
            </div>
        </StyledWalletCard>
    );
};

export default WalletCard;
