import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import ComingSoon from './pages/coming-soon';
import Home from './pages/home';
import BurnDetail from './pages/burn-detail';
import Header from './components/Header';
import {AuthProvider} from './Context/AuthContext';
import Footer from './components/Footer';
import BurnTransaction from './components/BurnTransaction';

const App = () => {
    const pathname = useLocation().pathname;
    const hideFooter = ['/', '/burn-detail'].includes(pathname) || pathname?.includes('/burn-detail');

    return (
        <>
            <AuthProvider>
                <Header />
                <Routes>
                    <Route path="/" element={<ComingSoon />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/burn-detail/:id" element={<BurnDetail />} />
                    <Route path="/share-burn-detail" element={<BurnTransaction />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                {!hideFooter && <Footer />}
            </AuthProvider>
        </>
    );
};

export default App;
