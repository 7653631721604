import React from 'react';
import usdt from '../../assets/usdt.svg';
import usdc from '../../assets/usdc.svg';
import sol from '../../assets/sol.svg';
import {StyledYesterDayProject} from './YesterDayProjects.styles';
import YesterDayProjectCard from '../YesterDayProjectCard';
import commingSoonbg from '../../assets/yesterdays-bg.png';

const projectCardData = [
    {
        coinImg: usdc,
        coinName: 'USDC',
        date: '22 : 08 : 2023',
        prizeAmount: '261,665 USDC',
        money1: '261,665 USDC',
        money2: '14,211 USD',
    },
    {
        coinImg: sol,
        coinName: 'SOL',
        date: '22 : 08 : 2023',
        prizeAmount: '261,665 SOL',
        money1: '261,665 SOL',
        money2: '14,211 USD',
    },
    {
        coinImg: usdt,
        coinName: 'USDT',
        date: '22 : 08 : 2023',
        prizeAmount: '261,665 USDT',
        money1: '261,665 USDT',
        money2: '14,211 USD',
    },
];

const YesterDayProjects = () => {
    return (
        <div className="container">
            <StyledYesterDayProject>
                <strong className="heading">Yesterdays Project Winners</strong>
                <div className="card-holder">
                    <YesterDayProjectCard arr={projectCardData} />
                </div>
                <div className="fakeBg">
                    <img src={commingSoonbg} alt="commingSoonbg" />
                </div>
            </StyledYesterDayProject>
        </div>
    );
};

export default YesterDayProjects;
