import styled, { css } from "styled-components";

export const TableRow = styled.tr`
  /* border-bottom: 1px solid #252429; */
  background: none;
  display: table-row;
  width: 100%;
  padding: 0;
  /* 
  &:nth-child(even) {
    background: #18171c;
  } */

  ${({ responsive }) =>
    responsive &&
    css`
      @media (max-width: 991px) {
        border-radius: 10px;
        border: 1px solid #252429;
        display: block;
        padding: 5px;
        position: relative;
      }
    `}

  &:hover {
    /* td {
      @media (min-width: 992px) {
        transition: background var(--animation-speed) ease-in-out;
        background: var(--black);
        cursor: pointer;
      }
    } */
  }
`;

export default TableRow;
