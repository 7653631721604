import styled from 'styled-components';

export const StyledAuthorization = styled.div`
    border: 1px solid rgba(73, 158, 255, 0.5);
    border-radius: 20px;
    padding: 0 20px 20px;
    background: linear-gradient(258deg, rgba(31, 31, 82, 0.4) 30.87%, rgba(27, 21, 77, 0.4) 75.41%);
    text-align: center;
    margin-bottom: 32px;
    position: relative;
    margin-top: 30px;

    @media (min-width: 992px) {
        padding: 0 40px 40px;
        margin-top: -5px;
    }
    @media (min-width: 1200px) {
        padding: 0 60px 60px;
    }

    .border-line {
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
    }

    .img-holder {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        background: linear-gradient(140.74deg, #5185ba 12.2%, #ce3cc6 64.98%, #bcc4e4 117.75%);
        margin: -25px auto 20px;
        z-index: 1;
        position: relative;

        @media (min-width: 576px) {
            width: 80px;
            height: 80px;
            margin: -40px auto 20px;
        }
        @media (min-width: 768px) {
            width: 100px;
            height: 100px;
            margin: -50px auto 20px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .heading {
        display: block;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        @media (min-width: 576px) {
            font-size: 42px;
            line-height: 46px;
        }
    }

    .text {
        display: block;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: var(--gray-50);
        margin-bottom: 15px;
        @media (min-width: 576px) {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 30px;
        }
    }

    .amount-holder {
        padding-bottom: 15px;
        border-bottom: 1px solid #494970;

        @media (min-width: 576px) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            padding-bottom: 30px;
        }

        @media (min-width: 1200px) {
            gap: 30px;
        }

        .amount {
            display: block;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 3px;

            @media (min-width: 768px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (min-width: 1200px) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .burny,
        .sol {
            width: 100%;
            max-width: 300px;
            padding: 16px 20px;
            border-radius: 20px;
            background: rgba(118, 182, 255, 0.1);
            backdrop-filter: blur(19px);
            margin: 0 auto 15px;

            @media (min-width: 576px) {
                margin: 0;
            }

            @media (min-width: 992px) {
                display: flex;
                gap: 20px;
            }

            .text {
                margin-bottom: 0;

                @media (min-width: 992px) {
                    text-align: start;
                }
                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: 17px;
                }
            }

            .burny-img,
            .sol-img {
                width: 40px;
                height: 40px;
                padding: 6px;
                border-radius: 8px;
                background: #16123e;
                box-shadow: 0px 4px 40px 0px rgba(80, 50, 211, 0.4);
                display: flex;
                align-items: center;
                margin: 0 auto 5px;

                @media (min-width: 768px) {
                    width: 55px;
                    height: 55px;
                    border-radius: 16px;
                    padding: 12px;
                }
            }
        }
        .sol {
            margin-bottom: 0;
        }
        .usd {
            width: 100%;
            max-width: 300px;
            margin: 0 auto 15px;
            @media (min-width: 576px) {
                margin: 0;
            }

            .amount {
                font-size: 22px;
                line-height: 26px;
                font-weight: 700;

                @media (min-width: 992px) {
                    font-size: 32px;
                    line-height: 36px;
                }

                @media (min-width: 1200px) {
                    font-size: 42px;
                    line-height: 46px;
                }
            }

            .text {
                margin-bottom: 0;
            }
        }
    }

    .top-projects {
        font-size: 17px;
        line-height: 21px;
        font-weight: 500;
        padding: 15px 0;
        border-bottom: 1px solid #494970;

        @media (min-width: 576px) {
            padding: 30px 0;
        }
    }

    .select-holder {
        padding-top: 15px;
        margin-bottom: 15px;

        @media (min-width: 576px) {
            padding-top: 30px;
            margin-bottom: 30px;
        }
        @media (min-width: 768px) {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 10px;
        }

        .select-token,
        .enter-amount {
            width: 100%;
            margin-bottom: 15px;

            @media (min-width: 768px) {
                max-width: 280px;
                margin-bottom: 0;
            }

            @media (min-width: 1200px) {
                max-width: 340px;
            }
        }

        .text {
            text-align: start;
            margin-bottom: 10px;
            margin-left: 32px;
            opacity: 0.6;
        }

        button {
            margin-bottom: 0;
        }
    }

    .transaction-failed {
        color: var(--danger);
        text-align: center;
        margin-bottom: 30px;
        opacity: 0.6;

        .heading {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            margin-bottom: 700;
        }

        .text {
            color: var(--danger);
        }
    }
`;
