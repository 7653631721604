import styled from 'styled-components';

export const StyledBurnDetail = styled.div`
    border-radius: 20px;
    border: 1px solid rgba(73, 158, 255, 0.5);
    background: linear-gradient(258deg, rgba(31, 31, 82, 0.4) 30.87%, rgba(27, 21, 77, 0.4) 75.41%);
    padding: 16px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    .fake-border {
        position: absolute;
        top: -1px;
        right: 50%;
        transform: translateX(50%);
        @media (max-width: 768px) {
            top: 0px;
        }
    }
    @media (min-width: 768px) {
        padding: 60px;
        font-size: 16px;
        line-height: 20px;
    }
    @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
    }
    h1 {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(89deg, #fff 46.93%, #5032d3 101.49%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 12px;
        @media (min-width: 768px) {
            margin-bottom: 24px;
            font-size: 32px;
            line-height: 36px;
        }
        @media (min-width: 992px) {
            margin-bottom: 35px;
            font-size: 42px;
            line-height: 46px;
        }
    }

    .amount-holder {
        display: block;
        .account-details {
            max-width: 100%;
            margin: 0 auto 8px;
            text-align: center;
            .address {
                justify-content: center;
            }
        }
        .amount-wrap {
            margin-bottom: 8px;
        }
        @media (min-width: 576px) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            padding-bottom: 30px;

            .account-details {
                max-width: 100%;
                margin: 0;
                text-align: left;
                .address {
                    justify-content: start;
                }
            }
            .amount-wrap {
                margin-bottom: 0;
            }
        }
    }
    .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
    }
    .btn-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
        max-width: 447px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 576px) {
            flex-direction: column-reverse;
            max-width: 193px;

            button {
                width: 100%;
            }
        }
    }
    .border-right {
        position: relative;
        padding-right: 8px;
        &:before {
            position: absolute;
            content: '';
            top: 2px;
            bottom: 2px;
            right: 0;
            width: 2px;
            background: #e7e2f9;
            opacity: 0.2;
        }
    }
`;

export const DetailSection = styled.div`
    max-width: 945px;
    width: 100%;
    margin: 0 auto 32px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    @media (min-width: 576px) {
        font-size: 16px;
        line-height: 20px;
    }
    @media (min-width: 992px) {
        font-size: 22px;
        line-height: 26px;
    }
    .flex {
        @media (max-width: 767px) {
            gap: 5px;
        }
    }
    .profile {
        margin: 0 auto 12px;
        display: flex;
        align-items: center;
        background: rgba(73, 158, 255, 0.5);
        width: 52px;
        height: 52px;
        flex-shrink: 0;
        border-radius: 52px;
        text-align: center;
        @media (min-width: 992px) {
            width: 77px;
            height: 77px;
            border-radius: 77px;
        }
        img {
            display: block;
            /* width: 77px;
            height: 77px; */
            object-fit: fill;
        }
    }
    .walletAddress {
        display: block;
        max-width: 140px;
        margin: 0 auto 12px;
    }
    .date {
        padding: 6px 12px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        border-radius: 8px;
        background: rgba(118, 182, 255, 0.1);
        display: block;
        margin-bottom: 12px;
        max-width: fit-content;
        margin: 0 auto 32px;
    }
    .account-details {
        max-width: 180px;
        width: 100%;
        padding: 6px;
        border-radius: 6px;
        background: rgba(118, 182, 255, 0.1);
        backdrop-filter: blur(19px);
        text-align: left;
        font-weight: 700;
        @media (min-width: 768px) {
            max-width: 250px;
        }
        @media (min-width: 992px) {
            max-width: 300px;
            border-radius: 20px;

            padding: 16px 20px;
        }
        .fake-label {
            display: block;
            margin-bottom: 2px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            @media (max-width: 768px) {
                font-size: 17px;
                line-height: 20px;
            }
        }
        .address {
            display: flex;
            align-items: center;
            gap: 8px;
            @media (max-width: 576px) {
                img {
                    width: 14px;
                    height: 14px;
                }
                .address-wrap {
                    max-width: 77px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .amount-wrap {
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 24px;
        }
        .amount {
            white-space: nowrap;
            display: block;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            text-transform: uppercase;
            background: linear-gradient(91deg, #fff 47.96%, #3044f4 132.02%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (min-width: 768px) {
                font-size: 20px;
                line-height: 24px;
            }
            @media (min-width: 1024px) {
                font-size: 42px;
                line-height: 46px;
            }
        }
    }
`;

export const OverViewAccount = styled.div`
    padding: 12px 0;
    border-top: 1px solid rgba(231, 226, 249, 0.2);
    border-bottom: 1px solid rgba(231, 226, 249, 0.2);
    margin: 12px 0;
    overflow: hidden;
    @media (min-width: 768px) {
        margin: 32px 0;
        padding: 32px 0;
    }
    .title {
        display: block;
        margin-bottom: 20px;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            font-weight: 400;
            margin-bottom: 12px;

            @media (min-width: 768px) {
                margin-bottom: 20px;
                display: flex;
                align-self: flex-start;
                gap: 5px;
            }
            .col-1 {
                max-width: 220px;
                width: 100%;
                flex-shrink: 0;
                margin-bottom: 10px;
                color: rgba(255, 255, 255, 0.6);
                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
            .col-2 {
                justify-content: flex-start;
                .longtext {
                    display: block;
                    max-width: 831px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    /* display: block;
                    overflow-wrap: break-word;
                    white-space: normal;
                    word-break: break-all; */
                }
            }
            .success {
                color: #44ef9a;
            }
        }
    }
    .copy-icon {
        cursor: pointer;
        width: 22px;
        height: 22px;
        flex-shrink: 0;
    }
`;
