import styled from 'styled-components';

export const StyledProjectCard = styled.div`
    width: 100%;
    flex-grow: 1;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #332b88;
    background: linear-gradient(258deg, rgba(31, 31, 82, 0.3) 30.87%, rgba(27, 21, 77, 0.3) 75.41%);
    transition: 0.3s;

    @media (min-width: 1300px) {
        padding: 20px;
    }

    .text {
        display: block;
        opacity: 0.6;
        font-weight: 500;
        text-align: center;
        margin-bottom: 8px;
    }

    .currency-name,
    .total-amount {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 22px;
        }
        @media (min-width: 1440px) {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .date-holder,
    .money {
        font-weight: 500;
        padding: 6px 10px;
        border-radius: 8px;
        background: rgba(118, 182, 255, 0.1);
        backdrop-filter: blur(19px);
        text-align: center;
        @media (min-width: 576px) {
            padding: 10px;
        }
    }

    .card-head {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #494970;
        padding-bottom: 15px;
        margin-bottom: 15px;
        @media (min-width: 576px) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        .coin-holder {
            display: flex;
            align-items: center;
            gap: 12px;

            .coin-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;

                @media (min-width: 576px) {
                    width: 26px;
                    height: 26px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .total-amount {
        padding: 6px 10px;
        text-align: center;
        border-radius: 8px;
        background: linear-gradient(80deg, #489dfe 11.53%, #3044f4 92.64%), rgba(118, 182, 255, 0.1);
        backdrop-filter: blur(19px);
        margin-bottom: 20px;
        @media (min-width: 576px) {
            padding: 10px 14px;
        }
    }

    .money-exchange {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-bottom: 1px solid #494970;
        padding-bottom: 15px;

        @media (min-width: 576px) {
            padding-bottom: 20px;
        }
        @media (min-width: 1200px) {
            gap: 20px;
        }

        .icon {
            width: 15px;
            height: 15px;
            flex-shrink: 0;
            @media (min-width: 576px) {
                width: 20px;
                height: 20px;
            }
        }

        .money {
            width: 100%;
            max-width: 150px;
            @media (max-width: 1199px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .media-icons-holder {
        display: flex;
        justify-content: center;
        gap: 12px;
        border-bottom: 1px solid #494970;
        padding: 15px 0;

        @media (min-width: 576px) {
            padding: 20px 0;
        }

        .icon-holder {
            width: 30px;
            height: 30px;
            border: 1px solid #499eff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 576px) {
                width: 45px;
                height: 45px;
            }

            img {
                width: 15px;
                height: 15px;
                @media (min-width: 576px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        .website,
        .transaction {
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 2px;
            @media (max-width: 575px) {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
            }
        }

        .transaction {
            color: #499dfe;
        }
    }
`;
