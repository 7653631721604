import React from 'react';
import Authorization from '../components/Authorization';
import ProjectsBurning from '../components/ProjectsBurning';
import WalletBurning from '../components/WalletBurning';
import HomeTable from '../components/HomeTable';
import TwoCol from '../components/TwoCol';
import YesterDayProjects from '../components/YesterDayProjects';

const Home = () => {
    return (
        <>
            <Authorization isAuthorized />
            <ProjectsBurning />
            <WalletBurning />
            <HomeTable />
            <TwoCol />
            <YesterDayProjects />
        </>
    );
};

export default Home;
