import styled, {css} from 'styled-components';

export const StyledCurrencyCard = styled.div`
    width: 100%;
    flex-grow: 1;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #332b88;
    transition: 0.3s;
    background: linear-gradient(258deg, rgba(31, 31, 82, 0.3) 30.87%, rgba(27, 21, 77, 0.3) 75.41%);

    @media (min-width: 1300px) {
        padding: 20px;
    }

    //active state
    ${({$active}) =>
        $active &&
        css`
            border: 1px solid #5f51ff;
            background: linear-gradient(164deg, rgba(0, 0, 0, 0) 11.19%, #4963ff 107.26%),
                linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%),
                linear-gradient(258deg, rgba(31, 31, 82, 0.7) 30.87%, rgba(27, 21, 77, 0.7) 75.41%);

            box-shadow: 0px 4px 10px 0px rgba(69, 147, 255, 0.5);
            backdrop-filter: blur(5px);
            @media (min-width: 576px) {
                /* transform: scale(1.05); */
                box-shadow: 0px 4px 40px 0px rgba(69, 147, 255, 0.5);
            }
        `}

    .currency-name,
    .total-coins {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 22px;
        }
        @media (min-width: 1440px) {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .card-number {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        @media (min-width: 1200px) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    .card-head {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #494970;
        padding-bottom: 15px;
        margin-bottom: 15px;
        @media (min-width: 576px) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        .coin-holder {
            display: flex;
            align-items: center;
            gap: 12px;

            .coin-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;

                @media (min-width: 576px) {
                    width: 26px;
                    height: 26px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .total-burned,
    .burn-rate,
    .time,
    .percentage {
        color: var(--gray-50);
        font-weight: 500;
        @media (max-width: 575px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .total-burned {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-bottom: 10px;
    }

    .total-coins {
        padding: 10px;
        border-radius: 8px;
        background: rgba(118, 182, 255, 0.1);
        backdrop-filter: blur(19px);
        text-align: center;
        margin-bottom: 15px;

        @media (min-width: 576px) {
            margin-bottom: 20px;
            padding: 14px;
        }
    }

    .burn-rate {
        display: block;
        text-align: center;
        margin-bottom: 10px; /* Keep only one margin-bottom */
    }

    .graph-holder {
        display: flex;
        align-items: center;
        gap: 25px;

        .graph-img {
            max-width: 190px;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .percentage {
            display: flex;
            align-items: center;
            gap: 5px;
            color: ${({$danger}) => ($danger ? 'var(--danger)' : '#44ef9a')};
            font-weight: 500;

            .arrow-icon {
                transform: rotate(${({$danger}) => ($danger ? '180deg' : '0')});
            }
        }
        .time {
            @media (max-width: 1439px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
`;
