import styled from 'styled-components';

export const StyledWalletCard = styled.div`
    width: 100%;
    flex-grow: 1;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #332b88;
    background: linear-gradient(258deg, rgba(31, 31, 82, 0.3) 30.87%, rgba(27, 21, 77, 0.3) 75.41%);
    transition: 0.3s;

    @media (min-width: 1300px) {
        padding: 20px;
    }

    .text {
        font-weight: 500;
    }

    .card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 26px;

        .card-number {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;

            @media (min-width: 1200px) {
                font-size: 26px;
                line-height: 30px;
            }
        }

        .date-holder {
            font-weight: 500;
            padding: 10px;
            border-radius: 8px;
            background: rgba(118, 182, 255, 0.1);
            backdrop-filter: blur(19px);
            text-align: center;
        }
    }

    .user-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #494970;
        padding-bottom: 15px;
        margin-bottom: 15px;

        @media (min-width: 576px) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        .coin-holder {
            display: flex;
            align-items: center;
            gap: 12px;

            .coin-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;

                @media (min-width: 576px) {
                    width: 26px;
                    height: 26px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .user {
            display: flex;
            align-items: center;
            gap: 12px;

            .img-holder {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                overflow: hidden;

                @media (min-width: 576px) {
                    width: 26px;
                    height: 26px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .card-footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .total-coins {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            padding: 10px;
            border-radius: 8px;
            background: rgba(118, 182, 255, 0.1);
            backdrop-filter: blur(19px);
            text-align: center;

            @media (min-width: 576px) {
                padding: 14px;
            }
            @media (min-width: 768px) {
                font-size: 18px;
                line-height: 22px;
            }
            @media (min-width: 1440px) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .screener {
            display: flex;
            align-items: center;
            gap: 2px;
            cursor: pointer;
        }
    }
`;
