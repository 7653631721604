import React from 'react';
import {StyledCommingSoon} from './CommingSoon.styles';
import commingSoonbg from '../../assets/commingSoonbg.png';
// import logoSm from '../../assets/logo-sm.svg';
// import Button from '../Button';

const CommingSoonPage = () => {
    return (
        <StyledCommingSoon>
            <div className="container">
                <div className="commingsoon">
                    <div className="content">
                        <h1>Coming soon ...</h1>
                        {/* <div className="btn-wrap">
                            <Button
                                outline="primary"
                                onClick={() => {
                                    window.open('https://t.me/burnydotfun', '_blank');
                                }}>
                                join Telegram
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    window.open(
                                        'https://www.pinksale.finance/solana/launchpad/Fmjyph4m3BvDmbT7gf2KBdP5FwXqN2hS9oKT12aHziQW',
                                        '_blank'
                                    );
                                }}>
                                Join Pre Sale
                                <img src={logoSm} alt="logoSm" />
                            </Button>
                        </div> */}
                    </div>
                    <div className="fakeBg">
                        <img src={commingSoonbg} alt="commingSoonbg" />
                    </div>
                </div>
            </div>
        </StyledCommingSoon>
    );
};

export default CommingSoonPage;
