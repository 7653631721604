import React, { useEffect } from "react";
import { ContentHolder, Head, StyledModal } from "./Modal.styles";

const CenterModal = ({
  children,
  open,
  setOpen,
  bg,
  padding,
  width,
  overflow,
  radius,
  desktopRight,
  desktopTop,
  title,
  setIsEditing,
  zIndex,
  iscloseAble = true,
}) => {
  useEffect(() => {
    // Function to disable scrolling when the modal is open
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Function to enable scrolling when the modal is closed
    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    if (open) {
      disableScroll();
    } else {
      enableScroll();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      enableScroll();
    };
  }, [open]);

  const handleClose = () => {
    setIsEditing?.({
      status: false,
    });
    setOpen(false);
  };

  return (
    open && (
      <StyledModal
        $zIndex={zIndex}
        open={open}
        onClick={handleClose}
        onKeyDown={(e) => {
          if (iscloseAble) {
            if (e.key === "Escape") {
              handleClose();
            }
          }
        }}
      >
        <ContentHolder
          $bg={bg}
          padding={padding}
          width={width}
          overflow={overflow}
          radius={radius}
          desktopRight={desktopRight}
          desktopTop={desktopTop}
          role="dialog"
          aria-modal="true"
          onClick={(e) => e.stopPropagation()}
          tabIndex={-1}
        >
          {/* <Head>
            {iscloseAble && (
              <button
                type="button"
                className="closer"
                onClick={handleClose}
                tabIndex={0}
              >
                <Image src={close} alt="close" />
              </button>
            )}
          </Head> */}
          {children}
        </ContentHolder>
      </StyledModal>
    )
  );
};

export default CenterModal;
