import React, {useEffect, useRef, useState} from 'react';
import {ItemWrapper, StyledPropertySelectWrapper} from './Select.styles';
import {MdOutlineKeyboardArrowDown} from 'react-icons/md';

const Select = ({
    className,
    title = 'Select...',
    option,
    placeholder,
    onChange,
    isMulti,
    color,
    name,
    error,
    noMargin,
}) => {
    const [value, setValue] = useState(title);
    const [dropDown, setDropDown] = useState(false);
    const closeRef = useRef();
    function handelChange(elem) {
        setValue(elem);
        setDropDown(!dropDown);
        // onChange(elem.value, title);
    }
    const handleClickOutside = event => {
        if (closeRef.current && !closeRef.current.contains(event.target)) {
            setDropDown(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropDown]);
    return (
        <StyledPropertySelectWrapper
            className={className}
            $icon={dropDown}
            ref={closeRef}
            placeholder={placeholder}
            $color={color}
            $error={error}
            $noMargin={noMargin}>
            <div className="skew">
                <div className="textFilter" onClick={() => setDropDown(!dropDown)}>
                    {value.label ? <span className="li-items">{value.label}</span> : <span>{title}</span>}
                    <div className="iconWrap">
                        <MdOutlineKeyboardArrowDown size="22" color="var(--gray-400)" />
                    </div>
                </div>
            </div>

            <ItemWrapper $height={dropDown}>
                {option?.map((elem, ind) => (
                    <li key={ind} onClick={() => handelChange(elem)}>
                        <div className="li-items">{elem.label}</div>
                    </li>
                ))}
            </ItemWrapper>
            <input type="hidden" value={value === 'Select...' ? '' : value?.value} name={name} />
        </StyledPropertySelectWrapper>
    );
};

export default Select;
