import styled, {css} from 'styled-components';

export const StyledTextField = styled.div`
    width: 100%;
    color: var(--white);
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    text-align: left;
    position: relative;
    margin-bottom: ${({$noMargin}) => ($noMargin ? '0' : '15px')};
    .fakeLabelWrapp {
        font-size: 17px;
        line-height: 21px;
        font-weight: 500;
        opacity: 0.6;
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 10px;
        margin-left: 32px;
        position: relative;
    }
    label {
        display: block;
    }

    .label {
        color: var(--gray-50);
    }
    .error {
        position: absolute;
        top: 0;
        right: 5px;
        color: var(--danger);

        p {
            margin: 0;
        }
    }
    figure {
        max-width: 16px;
        margin: 0;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .question {
            margin-left: auto;
            cursor: pointer;
            position: relative;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            .toolTip {
                position: absolute;
                left: 15px;
                bottom: -5px;
                width: 150px;
                background: #1a191f;
                border-radius: 5px;
                color: var(--gray-50);
                display: block;
                margin-bottom: 15px;
                opacity: 0;
                padding: 5px 10px;
                pointer-events: none;
                -webkit-transform: translateY(10px);
                -moz-transform: translateY(10px);
                -ms-transform: translateY(10px);
                -o-transform: translateY(10px);
                transform: translateY(10px);
                -webkit-transition: all 0.25s ease-out;
                -moz-transition: all 0.25s ease-out;
                -ms-transition: all 0.25s ease-out;
                -o-transition: all 0.25s ease-out;
                transition: all 0.25s ease-out;
                -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
                -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
                -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
                -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
                box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
            }
            &:hover {
                .toolTip {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
`;
export const InputStyled = styled.div`
    position: relative;
    width: 100%;
    padding: 0 20px;
    border-radius: 8px;
    border: 1px solid ${({$error}) => ($error ? '#F63737' : ')')};
    background: ${({$error}) => ($error ? 'rgba(246, 55, 55, 0.10)' : 'rgba(118, 182, 255, 0.1)')};
    backdrop-filter: blur(19px);
    box-sizing: border-box;
    margin-bottom: ${({$noMargin}) => ($noMargin ? '0' : '8px')};
    overflow: hidden;
    font-family: '';
    transform: skew(-15deg);
    font-family: 'Blender Pro';
    @media (min-width: 768px) {
        padding: 0 32px;
    }
    .notSkew {
        transform: skew(15deg);
        display: flex;
        align-items: center;
        input {
            width: 100%;
            flex-grow: 1;
        }
        .suffix {
            color: #a1a0ae;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
            @media (min-width: 768px) {
                font-size: 22px;
                line-height: 24px;
            }
        }
    }
    ${({$disable}) =>
        $disable &&
        css`
            background: #252429;
        `}
    .iconWrapper {
        position: absolute;
        left: 10px;
    }
    ${({$hasIcon}) =>
        $hasIcon &&
        css`
            display: flex;
            align-items: center;
            gap: 10px;
        `}
    input,textarea {
        color: var(--white);
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
        padding-right: 10px;
        background: transparent !important;
        width: 100%;
        height: 52px;
        border: none;
        outline: none;
        font-family: 'Blender Pro';
        @media (min-width: 768px) {
            padding: 0 16px;
            font-size: 22px;
            line-height: 24px;
        }
        &::placeholder {
            opacity: 0.8;
        }
        ${({$disable}) =>
            $disable &&
            css`
                background: var(--primary-50) !important;
            `}

        ${({$hasIcon}) =>
            $hasIcon &&
            css`
                padding: 0 16px 0 32px;
            `}
    }
    textarea {
        padding: 8px;

        height: 250px;
        resize: none;
    }
    /* Autofill styles */
    input:-webkit-autofill {
        background: var(--white) !important;
    }

    /* For Edge and Firefox */
    input:-moz-autofill {
        background: var(--white) !important;
    }

    /* For newer versions of Firefox */
    input:-internal-autofill-selected {
        background: var(--white) !important;
    }
    input:-webkit-autofill::before,
    input:-webkit-autofill::after {
        content: none !important;
    }
`;
