import React, {createContext, useContext, useState} from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [isAuthorized, setIsAuthorized] = useState(true);

    const authorize = () => setIsAuthorized(true);
    const unAuthorize = () => setIsAuthorized(false);

    return <AuthContext.Provider value={{isAuthorized, authorize, unAuthorize}}>{children}</AuthContext.Provider>;
};
