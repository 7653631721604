import styled from 'styled-components';

export const StyledBurnTransaction = styled.div`
    margin-bottom: 40px;

    .heading {
        display: block;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 15px;
        @media (min-width: 576px) {
            font-size: 22px;
            line-height: 25px;
        }
    }
    .cards-holder {
        &.desktop {
            display: none;
            @media (min-width: 576px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                gap: 16px;
            }
            @media (min-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        &.mobile {
            display: block;
            @media (min-width: 576px) {
                display: none;
            }
        }
        .slick-track {
            display: flex !important;
            gap: 10px;
            margin: 0 -3px;
        }
    }
`;
