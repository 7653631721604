import styled from 'styled-components';

export const StyledTwoCol = styled.div`
    @media (min-width: 768px) {
        display: flex;
        gap: 10px;
        margin-bottom: 32px;
    }
    @media (min-width: 1339px) {
        gap: 24px;
    }
    @media (min-width: 768px) {
        margin-bottom: 40px;
    }
    .col {
        margin-bottom: 32px;

        width: 100%;
        border-radius: 20px;
        border: 1px solid rgba(73, 158, 255, 0.5);
        background: linear-gradient(258deg, rgba(31, 31, 82, 0.4) 30.87%, rgba(27, 21, 77, 0.4) 75.41%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        padding: 40px 20px;
        @media (min-width: 768px) {
            margin-bottom: 0px;
        }
        @media (min-width: 1220px) {
            flex-direction: row;
        }
        @media (min-width: 1339px) {
            gap: 32px;
            padding: 40px;
        }
        .buttonwrap {
            /* max-width: 238px; */
            /* width: 100%; */
            /* margin: 0 auto; */
            @media (max-width: 1219px) {
                margin-top: 20px;
            }
        }
        .textwrap {
            color: var(--white);
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            text-align: center;

            @media (min-width: 1220px) {
                font-size: 20px;
                line-height: 24px;
                text-align: left;
            }
            .title {
                text-align: center;
                max-width: 250px;
                display: block;
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 28px;
                font-weight: 700;
                text-transform: uppercase;
                background: linear-gradient(89deg, #fff 46.93%, #5032d3 101.49%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @media (min-width: 1220px) {
                    font-size: 28px;
                    line-height: 32px;
                    text-align: left;
                }
                @media (min-width: 1400px) {
                    font-size: 32px;
                    line-height: 36px;
                }
            }
        }
        .view-transaction {
            position: relative;
            border-radius: 13px;
            background: rgba(37, 37, 37, 0.5);
            backdrop-filter: blur(40px);
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            padding: 8px 45px 8px 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            .time {
                position: absolute;
                top: 8px;
                right: 10px;
                color: rgba(235, 235, 245, 0.6);
                font-size: 13px;
                font-weight: 400;
                line-height: 15px;
            }
            .icon-wrap {
                border-radius: 8px;
                padding: 5px;
                width: 32px;
                height: 32px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #34aadf;
            }
            .text-wrap {
                .view-transaction-wrap {
                    margin-top: 4px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    label {
                        cursor: pointer;
                        display: block;
                        background: linear-gradient(77.32deg, #489dfe 14.1%, #3044f4 113.26%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        color: #fff;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
`;
