import styled from 'styled-components';

export const StyledHeader = styled.div`
    width: 100%;
    z-index: 20;

    transition: 0.5s all ease-in-out;

    .container {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: 768px) {
            padding: 20px 40px;
        }
    }
    .logoWrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
            max-width: 100%;
            max-width: 32px;
            height: auto;
            object-fit: cover;
        }
        span {
            display: block;
            width: 100%;
            font-weight: 700;
            background: linear-gradient(89.28deg, #ffffff 25.5%, #3044f4 359.27%);
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (min-width: 576px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
`;

export const UserStatus = styled.div`
    display: flex;
    gap: 8px;
    .status {
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid rgba(73, 158, 255, 0.5);
        /* border-image-source: linear-gradient(
      278.44deg,
      rgba(73, 158, 255, 0.35) 42.55%,
      rgba(48, 68, 244, 0.35) 84.23%
    );
    border-image-slice: 1; */
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        cursor: pointer;
        @media (min-width: 576px) {
            font-size: 17px;
            line-height: 21px;
            padding: 16px 15px;
        }

        &:first-child {
            padding: 10px;

            cursor: default;
            @media (min-width: 576px) {
                padding: 16px 24px;
            }
        }
    }
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        flex-shrink: 0;
    }
    .bg-danger {
        background: var(--danger);
    }
    .bg-success {
        background: var(--success);
    }
`;
