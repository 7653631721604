import React from 'react';
import {StyledSuccessFullyBurned} from './SuccessFullyBurned.styles';
import Button from '../Button';
import logsm from '../../assets/logo-sm.svg';

const SuccessFullyBurned = ({onClose = () => {}}) => {
    return (
        <StyledSuccessFullyBurned>
            <strong className="burned-title">You successfully burned 2000 USDT</strong>
            <div className="btn-wrap">
                <Button
                    outline="danger"
                    onClick={() => {
                        onClose();
                    }}>
                    Back
                </Button>
                <Button variant="danger">
                    View transaction on chain
                    <img src={logsm} alt="logo-sm" />
                </Button>
            </div>
        </StyledSuccessFullyBurned>
    );
};

export default SuccessFullyBurned;
