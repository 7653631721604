import React from 'react';

import twitterIcon from '../../assets/x-icon.svg';
import redditIcon from '../../assets/reddit-icon.svg';
import mediumIcon from '../../assets/medium-icon.svg';
import discordIcon from '../../assets/discord-icon.svg';
import {LiaExchangeAltSolid} from 'react-icons/lia';
import {IoIosArrowForward} from 'react-icons/io';
import {StyledProjectCard} from './YesterDayProjectCard.styles';

const YesterDayProjectCard = ({arr}) => {
    return (
        <>
            {arr?.map((item, index) => (
                <StyledProjectCard>
                    <div className="card-head">
                        <div className="coin-holder">
                            <figure className="coin-img">
                                <img src={item?.coinImg} alt="usdc" />
                            </figure>
                            <span className="currency-name">{item?.coinName}</span>
                        </div>
                        <div className="date-holder">
                            <span>{item.date}</span>
                        </div>
                    </div>
                    <span className="text">Prize Value</span>
                    <div className="total-amount">
                        <span>{item?.prizeAmount}</span>
                    </div>
                    <span className="text">Total of Tokens Burned</span>
                    <div className="money-exchange">
                        <div className="money">
                            <span>{item?.money1}</span>
                        </div>
                        <LiaExchangeAltSolid className="icon" />
                        <div className="money">
                            <span>{item?.money1}</span>
                        </div>
                    </div>
                    <div className="media-icons-holder">
                        <figure className="icon-holder">
                            <img src={twitterIcon} alt="twitter" />
                        </figure>
                        <figure className="icon-holder">
                            <img src={mediumIcon} alt="medium" />
                        </figure>
                        <figure className="icon-holder">
                            <img src={discordIcon} alt="discord" />
                        </figure>
                        <figure className="icon-holder">
                            <img src={redditIcon} alt="reddit" />
                        </figure>
                    </div>
                    <div className="card-footer">
                        <div className="website">
                            <span>Go to Website</span>
                            <IoIosArrowForward />
                        </div>
                        <div className="transaction">
                            <span>View Transaction</span>
                            <IoIosArrowForward />
                        </div>
                    </div>
                </StyledProjectCard>
            ))}
        </>
    );
};

export default YesterDayProjectCard;
