import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import coin from '../../assets/ustd.svg';
import TableLayout from '../TableLayout';
import Table from '../Table';
const HomeTable = () => {
    const navigate = useNavigate();

    const actionBtns = item => {
        return (
            <button className="action-Btn-table" onClick={() => navigate(`/burn-detail/${item}`)}>
                <label>View Transaction</label>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path
                        d="M1.01465 10.9198L5.64639 6.28806C5.84165 6.0928 5.84165 5.77622 5.64639 5.58096L1.14588 1.08045"
                        stroke="url(#paint0_linear_49_28335)"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_49_28335"
                            x1="1"
                            y1="5.99976"
                            x2="6"
                            y2="5.99976"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#499DFE" />
                            <stop offset="1" stop-color="#605AFF" />
                        </linearGradient>
                    </defs>
                </svg>
            </button>
        );
    };

    const fromWallte = item => {
        return (
            <div className="action-Btn-table">
                <img src={coin} alt="coin" />
                <span>{item}</span>
            </div>
        );
    };
    const columnNamess = [`#`, `Time`, `From Wallet`, 'To', '$Burns', ''];
    const rowData = [
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
        {
            time: '22 : 08 : 2023',
            from: '3aG9P ... oJxKp',
            to: '3aG9P ... oJxKp',
            burn: '261,665',
        },
    ];
    const {product_rows, totalItems} = useMemo(() => ({
        product_rows: rowData?.map((item, ind) => [
            ind < 9 ? `0${ind + 1}` : ind || '------------',
            item.time || '------------',
            fromWallte(item.from) || '------------',
            item.to || '------------',
            item.burn || '------------',
            actionBtns(ind),
        ]),
    }));
    return (
        <div className="container">
            <TableLayout
                header={
                    <div className="title-head">
                        <div className="title-box">
                            <strong className="table-title">Latest Burn Transaction</strong>
                        </div>
                    </div>
                }>
                <Table width={992} rowsData={product_rows} columnNames={columnNamess} noPadding responsive={false} />
            </TableLayout>
        </div>
    );
};

export default HomeTable;
