import styled, {css} from 'styled-components';

export const StyledPropertySelectWrapper = styled.div`
    width: 100%;
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: ${({$noMargin}) => ($noMargin ? '0' : '5px')};
    @media (min-width: 768px) {
        font-size: 22px;
        line-height: 24px;
    }
    .li-items {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    &.selectProfile {
        max-width: 200px;
    }
    .skew {
        transform: skew(-15deg);
        border-radius: 8px;
        position: relative;
        background: ${({$error}) => ($error ? 'rgba(246, 55, 55, 0.10)' : 'rgba(118, 182, 255, 0.10);')};
        ${({$error}) =>
            $error &&
            css`
                border: 1px solid #f63737;
            `}
    }
    .textFilter {
        transform: skewX(15deg);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 13px 32px;
        z-index: 2 !important;
    }
    .iconWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        transform: ${({$icon}) => ($icon ? 'scale(-1)' : 'scale(1)')};
    }
`;
export const ItemWrapper = styled.ul`
    margin-left: -4px;
    position: absolute;
    left: 0px;
    top: 62px;
    z-index: 10 !important;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #252429;
    background: rgba(118, 182, 255, 0.1);
    backdrop-filter: blur(19px);
    -webkit-backdrop-filter: blur(19px);  /* Safari-specific */
    padding: 10px 25px;
    box-shadow: 0px 24px 52px -14px rgba(29, 41, 57, 0.16);
    max-height: ${({$height}) => ($height ? '282px' : '0%')};
    opacity: ${({$height}) => ($height ? '1' : '0')};
    visibility: ${({$height}) => ($height ? 'visible' : 'hidden')};
    overflow-y: auto;

    li {
        text-align: left;
        opacity: ${({$height}) => ($height ? '1' : '0')};
        visibility: ${({$height}) => ($height ? 'visible' : 'hidden')};
        padding: 8px 10px;
        margin: 0 !important;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        border-radius: 8px;
        transform: skew(-15deg);
        .li-items {
            transform: skew(15deg);
        }
        &:hover {
            background: rgba(118, 182, 255, 0.1);
            backdrop-filter: blur(19px);
            color: var(--white);
        }
    }
`;
