import React from 'react';
import {StyledCheckbox} from './Checkbox.styles';

const Checkbox = ({name, label}) => {
    return (
        <StyledCheckbox>
            <div className="form-group">
                <input type="checkbox" id={name} />
                <label htmlFor={name}>{label}</label>
            </div>
        </StyledCheckbox>
    );
};

export default Checkbox;
