import styled from 'styled-components';

export const StyledHowBunnyWork = styled.div`
    max-height: calc(100dvh - 158px);
    overflow-y: auto;
    padding: 50px 15px 30px;
    @media (min-width: 992px) {
        padding: 80px 60px 60px;
    }
    .title {
        display: block;
        margin-bottom: 32px;
        text-align: center;
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(91deg, #fff 47.96%, #f43030 132.02%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (min-width: 992px) {
            font-size: 32px;
            line-height: 36px;
        }
        @media (min-width: 992px) {
            font-size: 48px;
            line-height: 52px;
        }
    }
    .desc {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        font-weight: 400;
        text-align: left;
        @media (min-width: 992px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }
    .btnWrap {
        margin-top: 32px;
        text-align: center;
    }
    .video-wrap {
        overflow: hidden;
        position: relative;
        border: 1px solid rgba(73, 158, 255, 0.5);
        border-radius: 20px;
        padding: 16px;
        img {
            width: 100%;
            max-width: 100%;
            border-radius: 20px;
        }
        .fakeAfter,
        .fakeBefore {
            position: absolute;
            top: -1.5px;
        }
        .fakeBefore {
            top: auto;
            bottom: -1.5px;
        }
    }
    .play-button {
        position: absolute;
        z-index: 5;
        cursor: pointer;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50px);
        display: flex;
        width: 56px;
        height: 56px;
        padding: 13px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: rgba(118, 182, 255, 0.1);
        backdrop-filter: blur(19px);
        img {
            width: 100%;
            height: auto;
        }
    }
    .list-group {
        padding: 0;
        list-style: none;
        .listItems {
            display: flex;
            align-items: flex-start;
            gap: 6px;
            margin-bottom: 15px;
            @media (min-width: 768px) {
                gap: 16px;

                margin-bottom: 32px;
            }
            .text {
                color: var(--white);
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                @media (min-width: 768px) {
                    font-size: 17px;
                    line-height: 22px;
                }
            }
            .list-title {
                display: block;
                margin-bottom: 5px;
                color: #fff;
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                @media (min-width: 768px) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
        }
        .image-wrapper {
            max-width: 24px;
            width: 100%;
            flex-shrink: 0;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
`;
