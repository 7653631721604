import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ $gap }) => ($gap ? $gap : "10px")};
  padding: 10px 20px;
  margin: 0 0 10px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: ${({ $width }) => $width && $width};
  min-width: 100px;
  background: linear-gradient(80deg, #489dfe 11.53%, #3044f4 92.64%);
  color: var(--white);
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
  transform: skewX(-15deg);
  @media (min-width: 768px) {
    padding: 13px 30px;
    font-size: 22px;
    line-height: 26px;
  }
  .btn-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${({ $gap }) => ($gap ? $gap : "10px")};

    transform: skewX(15deg);
  }
  &:hover {
    opacity: 0.85;
    /* box-shadow: 10px 10px 25px rgba(95, 89, 255, 0.5); */
  }

  ${({ $sm }) =>
    $sm &&
    css`
      font-size: 12px;
      line-height: 18px;
      padding: 12px 20px;
      font-weight: 400;
    `}

  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `}

  /***** Background-Variants-Start *****/
  ${({ $variant }) =>
    $variant === "primary" &&
    css`
      background: linear-gradient(80deg, #489dfe 11.53%, #3044f4 92.64%);
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1; /* To place the shadow below the box */
        background: linear-gradient(
          89deg,
          #489dfe 22.59%,
          #3970fe 49.31%,
          #5f59ff 74.25%
        );
        opacity: 0.6;
        filter: blur(30px);
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      }
    `}
  ${({ $variant }) =>
    $variant === "danger" &&
    css`
      background: linear-gradient(82.87deg, #fe4848 8.53%, #f43030 68.51%);
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1; /* To place the shadow below the box */
        background: linear-gradient(89deg, #FE4848 22.59%, #FE3939 49.31%, #FF5959 74.25%)
        opacity: 0.6;
        filter: blur(30px);
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      }
    `}
  ${({ $variant }) =>
    $variant == "light" &&
    css`
      background: var(--white);
      color: var(--black);
    `}
  ${({ $variant }) =>
    $variant === "blur" &&
    css`
      background: rgba(255, 255, 255, 0.05);
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
      color: var(white);
    `}
 
  /*****************Background Variants End*********************/


  /*****************Border Variants Start*********************/

  
  ${({ $outline }) =>
    $outline === "primary" &&
    css`
      border-radius: 10px;
      background: transparent;
      border: 1px solid #489dfe;
      color: var(--white);
    `}
  ${({ $outline }) =>
    $outline === "danger" &&
    css`
      border-radius: 10px;
      background: transparent;
      border: 1px solid #f63737;
      color: var(--white);
    `}
  ${({ $outline }) =>
    $outline == "success" &&
    css`
      border: 1px solid var(--success-500);
      background: transparent;
      color: var(--success-500);

      &:hover {
        background: var(--success-500);
        color: var(--white);
      }
    `}

    
    /*****************Border Variants End*********************/

    .loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
