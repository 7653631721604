import React from 'react';
import {InputStyled, StyledTextField} from './TextField.styles';

const Input = ({
    Field_Name,
    label,
    error,
    toolTip,
    className,
    hasIcon,
    disabled,
    type = 'text',
    suffix,
    name,
    noMargin,
    ...rest
}) => {
    return (
        <StyledTextField className={className} $noMargin={noMargin}>
            {label && (
                <div className="fakeLabelWrapp">
                    <label htmlFor={label} className="label">
                        {label}
                    </label>
                </div>
            )}
            <InputStyled
                $error={error}
                className="inputstyle_custom"
                $hasIcon={hasIcon}
                $disable={disabled}
                $noMargin={noMargin}>
                <div className="notSkew">
                    {hasIcon && <div className="iconWrapper">{hasIcon}</div>}
                    {type === 'text' && <input {...rest} id={label} disabled={disabled} name={name} />}
                    {type === 'textarea' && <textarea {...rest} id={label} disabled={disabled} />}
                    <div className="suffix">{suffix}</div>
                </div>
            </InputStyled>
            {error && <span className="error">{error}</span>}
        </StyledTextField>
    );
};

export default Input;
