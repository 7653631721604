import React from "react";
import { StyledTableLayout } from "./TableLayout.styles";
import Pagination from "../Pagination";

function TableLayout({
  onChangeFilters = () => {},
  children,
  currentPage = 1,
  pageSize = 10,
  totalCount = 0,
  totalPages = 0,
  header,
}) {
  return (
    <>
      <StyledTableLayout>
        {header && header}
        <div className="childWrapper">
          {children}
          {!!totalCount && totalCount > pageSize && (
            <Pagination
              page={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              totalPages={totalPages}
              onPageChange={(_) => onChangeFilters({ filter: "", page: _ })}
            />
          )}
        </div>
      </StyledTableLayout>
    </>
  );
}

export default TableLayout;
