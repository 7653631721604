import React, {useState} from 'react';
import {StyledAuthorization} from './Authorization.styles';
import Logo from '../../assets/logo-sm.svg';
import profile from '../../assets/profile.png';
import burny from '../../assets/usd-coin-usdc-logo.svg';
import sol from '../../assets/solana-white.svg';
import borderLine from '../../assets/border-line.png';
import Button from '../Button';
import Select from '../Select';
import Input from '../TextField';
import {selectValues} from '../Constant';
import Checkbox from '../Checkbox';
import {useAuth} from '../../Context/AuthContext';
import CenterModal from '../Modal/CenterModal';
import SuccessFullyBurned from '../SuccessFullyBurned';

const Authorization = () => {
    const [error, setError] = useState(false);
    const [showBurnModal, setShowBurnModal] = useState(false);
    const {isAuthorized} = useAuth();

    return (
        <div className="container">
            <StyledAuthorization>
                {isAuthorized && <img src={borderLine} alt="borderLine" className="border-line" />}
                <div className="img-holder">{isAuthorized && <img src={profile} alt="robotImg" />}</div>
                {isAuthorized ? (
                    <div className="amount-holder">
                        <div className="burny">
                            <figure className="burny-img">
                                <img src={burny} alt="burny" />
                            </figure>
                            <div>
                                <span className="amount">19,515.00 $FKETH</span>
                                <span className="text">LhQFt ... JtL4W</span>
                            </div>
                        </div>
                        <div className="usd">
                            <span className="amount textgradient">6,749.00 USD</span>
                            <span className="text">Balance</span>
                        </div>
                        <div className="sol">
                            <figure className="sol-img">
                                <img src={sol} alt="sol" />
                            </figure>
                            <div>
                                <span className="amount">19,515.00 $GME</span>
                                <span className="text">LhQFt ... JtL4W</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <strong className="heading">Authorization</strong>
                )}
                {isAuthorized ? (
                    <div className="top-projects">
                        <p>
                            The top 3 projects will get a share of the fee distribution. <br /> Make sure your project
                            is ranked to get this bonus!
                        </p>
                    </div>
                ) : (
                    <span className="text">To authorize, connect your wallet.</span>
                )}
                {isAuthorized ? (
                    <>
                        <div className="select-holder">
                            <div className="select-token">
                                <label className="text">Select Tokens</label>
                                <Select option={selectValues} noMargin onChange={e => console.log(e)} error={error} />
                            </div>
                            <div className="enter-amount">
                                <Input
                                    label="Amounts"
                                    placeholder="Enter Amount"
                                    suffix="SOL"
                                    noMargin
                                    error={error && 'Insufficient Balance'}
                                    onChange={({target: {value}}) => {
                                        if (value < 10) {
                                            setError(true);
                                        } else {
                                            setError(false);
                                        }
                                    }}
                                />
                            </div>
                            <Button
                                variant={'primary'}
                                width="150px"
                                onClick={() => {
                                    setShowBurnModal(true);
                                }}>
                                BURN <img src={Logo} alt="Logo" />
                            </Button>
                        </div>
                        {error && (
                            <div className="transaction-failed">
                                <span className="heading">Transaction Failed</span>
                                <span className="text">
                                    Your burn transaction was unsuccessful. This could be caused by a network problem or
                                    invalid parameters. Try again in a moment.
                                </span>
                            </div>
                        )}
                        <Checkbox
                            label="I agree to the description outlined and hereby confirm my acceptance of the terms and conditions."
                            name="agree"
                        />
                    </>
                ) : (
                    <Button variant={'primary'}>
                        Connect Wallet <img src={Logo} alt="Logo" />{' '}
                    </Button>
                )}
            </StyledAuthorization>
            <CenterModal open={showBurnModal} width="1092" setOpen={setShowBurnModal}>
                <SuccessFullyBurned onClose={() => setShowBurnModal(false)} />
            </CenterModal>
        </div>
    );
};

export default Authorization;
