import React from 'react';
import {IoIosArrowForward} from 'react-icons/io';
import {FiSend} from 'react-icons/fi';
import {IoCopyOutline} from 'react-icons/io5';
import {StyledTransactionCard} from './BurnTransactionCard.styles';

const BurnTransactionCard = ({date, coinImg, coinName, user, totalCoin}) => {
    return (
        <StyledTransactionCard>
            <div className="card-head">
                <div className="date-holder">
                    <span>{date}</span>
                </div>
                <FiSend className="icon" />
            </div>
            <div className="user-holder">
                <div className="coin-holder">
                    <figure className="coin-img">
                        <img src={coinImg} alt="coinImg" />
                    </figure>
                    <span className="text">{coinName}</span>
                </div>
                <div className="user">
                    <span className="text">{user}</span>
                    <IoCopyOutline className="icon" />
                </div>
            </div>
            <div className="card-footer">
                <div className="total-coins">
                    <span>{totalCoin}</span>
                </div>
                <div className="screener">
                    <span className="text">Generate Image</span>
                    <IoIosArrowForward />
                </div>
            </div>
        </StyledTransactionCard>
    );
};

export default BurnTransactionCard;
