import styled from 'styled-components';

export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    .link-wrapper {
        max-width: 40px;
        width: 100%;
        @media (min-width: 768px) {
            max-width: 64px;
        }
    }
`;
