import styled, {css} from 'styled-components';

const styles = css`
    position: relative;
    text-align: ${({center}) => (center ? 'center' : 'left')};
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 20px;
    }

    ${({responsive}) =>
        responsive
            ? css`
                  @media (min-width: 992px) {
                      display: table-cell;
                      padding: 16px 10px;

                      &:first-child {
                          border-radius: 8px 0 0;
                      }
                      &:last-child {
                          border-radius: 0 8px 0 0;
                      }
                  }
              `
            : css`
                  display: table-cell;
                  padding: 12px 0px;
              `}
`;

export const Th = styled.th`
    ${styles}
    text-transform: capitalize;
    font-weight: 500;
    color: #a8a9b3;
    &:first-child {
        padding-left: 15px !important;
    }
`;

export const Td = styled.td`
    ${styles}
    ${({responsive}) =>
        responsive &&
        css`
            display: flex;
            justify-content: space-between;
            padding: 16px 5px !important;
            &:before {
                content: attr(data-th);
                font-weight: 500;
                display: inline-block;
                color: var(--gray);
                padding-right: 10px;

                @media (min-width: 992px) {
                    display: none;
                }
            }
        `}
  color:var(--white);
    &:first-child {
        color: #a8a9b3;
        text-align: left;
        padding-left: 10px !important;
    }
    &:last-child {
        min-width: 150px;
        text-align: right;
        padding-right: 20px !important;
    }
`;
